import "./Layout.scss";
import Footer from "../Footer/Footer";
import HeaderAndIntro from "../HeaderAndIntro/HeaderAndIntro";
import HowItWorks from "../HowItWorks/HowItWorks";
import HowToSurrender from "../HowToSurrender/HowToSurrender";
import WhereWeSearch from "../WhereWeSearch/WhereWeSearch";
import FaqAndHowPowsLive from "../FaqAndHowPowsLive/FaqAndHowPowsLive";
import FillSearchApplication from "../FillSearchApplication/FillSearchApplication";

export default function Layout() {
  return (
    <div className="Layout">
      <HeaderAndIntro />
      <FillSearchApplication />
      <HowItWorks />
      <WhereWeSearch />
      <HowToSurrender />
      <FaqAndHowPowsLive />
      <Footer />
    </div>
  );
}
