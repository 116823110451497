import "./CarouselDots.scss";

export interface CarouselDotsProps {
  total: number;
  activeIndex: number;
}

export default function CarouselDots({
  activeIndex,
  total,
}: CarouselDotsProps) {
  return (
    <div className="CarouselDots">
      <ul>
        {[...Array(total)].map((e, i) => (
          <li key={i}>
            <div
              className={["button", activeIndex === i ? "active" : ""].join(
                " "
              )}
            ></div>
          </li>
        ))}
      </ul>
    </div>
  );
}
