import { PropsWithChildren } from "react";
import "./Tile.scss";

export interface TileProps extends PropsWithChildren {
  title: string;
}

export default function Tile({ title, children }: TileProps) {
  return (
    <div className="Tile">
      <div className="title">{title}</div>
      <div className="content">{children}</div>
    </div>
  );
}
