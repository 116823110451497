import Logo1240 from "./1240+.svg";
import Logo905_1239 from "./905-1239.svg";
import Logo600_904 from "./600-904.svg";
import Logo_599 from "./-599.svg";
import "./LogoAndMotto.scss";

export default function LogoAndMotto() {
  return (
    <a
      className="LogoAndMotto"
      href="https://hochunaiti.com"
      aria-label="ХОЧУ НАЙТИ. Единый центр поиска военнослужащих армии РФ в Украине"
    >
      <picture>
        <source
          srcSet={Logo_599}
          media="(max-width: calc(600px - 1px + 17px))"
        />
        <source
          srcSet={Logo600_904}
          media="(max-width: calc(905px - 1px + 17px))"
        />
        <source
          srcSet={Logo905_1239}
          media="(max-width: calc(1240px - 1px + 17px))"
        />
        <img
          src={Logo1240}
          alt="ХОЧУ НАЙТИ. Единый центр поиска военнослужащих армии РФ в Украине"
        />
      </picture>
    </a>
  );
}
