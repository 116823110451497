import "./HowItWorks.scss";
import HowItWorksIntro from "./HowItWorksIntro/HowItWorksIntro";
import Tile from "./Tile/Tile";

export default function HowItWorks() {
  return (
    <article className="HowItWorks">
      <h2>Как работает поиск военных в Украине?</h2>

      <div className="tilesContainer">
        <HowItWorksIntro />

        <Tile title={"В плену"}>
          Военнослужащий армии РФ имеет статус военнопленного и пребывает в
          предусмотренном законодательством Украины месте для содержания
          военнопленных
        </Tile>

        <Tile title={"Погиб, тело (останки) пребывает в Украине"}>
          Тело (останки) военнослужащего армии РФ найдено, идентифицировано и
          находится в Украине
        </Tile>

        <Tile title={"Погиб, тело (останки) передано в Россию"}>
          Тело (останки) военнослужащего армии РФ найдено, идентифицировано и
          передано российской стороне в рамках репатриации
        </Tile>

        <Tile title={"Возвращен в Россию в рамках обмена"}>
          Передан представителям МО РФ в рамках обмена военнопленными и больше
          не пребывает на территории Украины
        </Tile>

        <Tile title={"Информация отсутствует"}>
          Информация о нахождении в плену или гибели отсутствует
        </Tile>
      </div>
    </article>
  );
}
