import Faq from "../Faq/Faq";
import HowPowsLive from "../HowPowsLive/HowPowsLive";
import "./FaqAndHowPowsLive.scss";

export default function FaqAndHowPowsLive() {
  return (
    <div className="FaqAndHowPowsLive">
      <Faq />
      <HowPowsLive />
    </div>
  );
}
