import { PropsWithChildren } from "react";
import "./LinkButton.scss";

export interface LinkButtonProps extends PropsWithChildren {
  variant?: "mostPopular" | "highlightOnHover" | "underlined";
  className?: string;
  href: string;
}

export default function LinkButton({
  children,
  variant,
  className,
  href,
}: LinkButtonProps) {
  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a
      className={["LinkButton", className ?? "", variant ?? ""].join(" ")}
      href={href}
      target="_blank"
    >
      {children}
    </a>
  );
}
