/* eslint-disable react/jsx-no-target-blank */
import "./SearchButton.scss";
import { ReactComponent as SoldierLogo } from "../../soldier-logo.svg";
import { ReactComponent as SearchIcon } from "./search.svg";

export default function SearchButton() {
  return (
    <div className="SearchButton">
      <a href="https://t.me/hochunaiti_bot" target="_blank">
        Искать
        <SoldierLogo className="SoldierLogo" height={36} width={36} />
        <SearchIcon className="SearchIcon" />
      </a>
    </div>
  );
}
