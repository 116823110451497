import "./Carousel.scss";
import { ReactComponent as ArrowRight } from "./arrow-right.svg";
import { ReactComponent as ArrowLeft } from "./arrow-left.svg";
import { PropsWithChildren, useCallback, useEffect, useRef } from "react";

export interface CarouselProps extends PropsWithChildren {
  currentIndex: number;
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
  photos: string[];
}

const autoplayInterval = 5000;

export default function Carousel({
  currentIndex,
  photos,
  setCurrentIndex,
}: CarouselProps) {
  const containerRef = useRef(null as null | HTMLUListElement);

  const moveForward = useCallback(() => {
    setCurrentIndex((currentIndex) => (currentIndex + 1) % photos.length);
  }, [photos.length, setCurrentIndex]);

  const moveBackward = useCallback(() => {
    setCurrentIndex((currentIndex) => {
      let newIndex = currentIndex - 1;

      if (newIndex < 0) {
        newIndex = photos.length + newIndex;
      }

      return newIndex;
    });
  }, [photos.length, setCurrentIndex]);

  useEffect(() => {
    containerRef.current!.scrollTo(
      currentIndex * containerRef.current!.clientWidth,
      0
    );
  }, [currentIndex, moveForward]);

  useEffect(() => {
    const interval = setInterval(() => {
      moveForward();
    }, autoplayInterval);
    return () => clearInterval(interval);
  }, [moveForward]);

  return (
    <div className="Carousel">
      <ul className="itemsContainer" ref={containerRef}>
        {photos.map((p) => (
          <li key={p}>
            <img src={p} alt="" />
          </li>
        ))}
      </ul>
      <div className="overlay">
        <button
          aria-label="Предьідущее изображение"
          className="left"
          onClick={() => moveBackward()}
        >
          <ArrowLeft />
        </button>
        <button
          aria-label="Следущее изображение"
          className="right"
          onClick={() => moveForward()}
        >
          <ArrowRight />
        </button>
      </div>
    </div>
  );
}
