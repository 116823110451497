/* eslint-disable react/jsx-no-target-blank */
import "./Faq.scss";
import FaqItem from "./FaqItem/FaqItem";

export default function Faq() {
  return (
    <div className="Faq">
      <h3 className="title">Часто задаваемые вопросы</h3>
      <div className="content">
        <ul>
          <FaqItem title="Какой информацией вы располагаете?">
            «Хочу найти» – это государственный проект Украины, в рамках которого
            родственники и близкие пропавших без вести военнослужащих армии РФ
            могут оставить заявку на поиск в Украине. Мы можем подтвердить
            нахождение военнослужащих в Украине в статусе военнопленных и
            погибших, тела или останки которых удалось идентифицировать.
          </FaqItem>

          <FaqItem title="Как связаться с военнопленным, передать ему посылку/письмо?">
            Если вы оставили заявку на поиск в чат-боте и получили подтверждение
            о нахождении военнослужащего в плену, консультант проекта ознакомит
            вас с предусмотренными законодательством Украины возможностями
            коммуникации и передачи посылок, корреспонденции и денег для
            военнопленного в лагерь.
          </FaqItem>

          <FaqItem title="Мой близкий больше недели не выходит на связь. Вы можете помочь его найти?">
            В ситуации активных боевых действий из соображений военной
            необходимости, большого количества погибших и взятых в плен, а также
            из-за трудностей c извлечением и идентификацией останков, информация
            может обновляться с задержкой. Примерное время ожидания ответа на
            запросы – 10 рабочих дней.
          </FaqItem>

          <FaqItem title="Я оставила заявку и получила ответ, что информация по человеку отсутствует.">
            Это означает, что такого человека нет среди военнопленных и
            идентифицированных останков. У нас нет информации по тех, кто ушел в
            СОЧ, кого собственные командиры «посадили на яму», чье тело бросили
            сослуживцы на российской стороне.
          </FaqItem>

          <FaqItem title="Мне кто-то позвонил и сказал, что мой близкий в плену и для его возвращения нужно заплатить деньги.">
            Сейчас появилось очень много мошенников, которые используют
            персональные данные пропавших без вести военнослужащих, которые их
            родственники публикуют в интернете. Если вам пишут или звонят с
            предложениями за деньги помочь в поиске, передать посылку, включить
            в списки на обмен и т.д - с вами работает мошенник. Проект «Хочу
            найти» – единственный официальный источник получения информации и мы
            не требуем за это никаких денег.
          </FaqItem>

          <FaqItem title="Мой близкий находится в плену, но российские чиновники не признают его военнопленным. Что делать?">
            Проект «Хочу найти» предоставляет информацию, но мы не консультируем
            по поводу проблем, связанных с российской бюрократией. Обращайтесь в{" "}
            <a href="https://t.me/dvizhenie_nashvyhod" target="_blank">
              Общественное движение по поиску и возвращению домой военных армии
              РФ “Наш Выход”
            </a>
          </FaqItem>
        </ul>
      </div>
    </div>
  );
}
