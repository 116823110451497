import { PropsWithChildren } from "react";
import "./WhereWeSearchTile.scss";

export interface WhereWeSearchTileProps extends PropsWithChildren {
  title: string;
}

export default function WhereWeSearchTile({
  title,
  children,
}: WhereWeSearchTileProps) {
  return (
    <div className="WhereWeSearchTile">
      <h4 className="title">{title}</h4>
      <div className="divider"></div>
      <div className="content">{children}</div>
    </div>
  );
}
