/* eslint-disable react/jsx-no-target-blank */
import { ReactComponent as Telegram } from "./telegram.svg";
import { ReactComponent as Facebook } from "./facebook.svg";
import { ReactComponent as Youtube } from "./youtube.svg";
import { ReactComponent as Tiktok } from "./tiktok.svg";
import "./SmButtons.scss";

export function SmButtons() {
  return (
    <div className="SmButtons">
      <ul>
        <li>
          <a
            href="https://t.me/hochunaiti_com"
            target="_blank"
            aria-label='Telegram-канал проекта "Хочу найти"'
          >
            <Telegram />
          </a>
        </li>
        <li>
          <a
            href="https://www.facebook.com/hochunaiti"
            target="_blank"
            aria-label='Facebook-страница проекта "Хочу найти"'
          >
            <Facebook />
          </a>
        </li>
        <li>
          <a
            href="https://www.youtube.com/@hochunaiti"
            target="_blank"
            aria-label='Youtube-канал проекта "Хочу найти"'
          >
            <Youtube />
          </a>
        </li>
        <li>
          <a
            href="https://www.tiktok.com/@hochunaiti.com"
            target="_blank"
            aria-label='TikTok проекта "Хочу найти"'
          >
            <Tiktok />
          </a>
        </li>
      </ul>
    </div>
  );
}
