import Carousel from "./Carousel/Carousel";
import CarouselDots from "./CarouselDots/CarouselDots";
import "./HowPowsLive.scss";
import { photos } from "./Carousel/photos";
import { useState } from "react";

export default function HowPowsLive() {
  const [currentIndex, setCurrentIndex] = useState(0);

  return (
    <div className="HowPowsLive">
      <h3 className="title">Как живут военнопленные</h3>
      <div className="content">
        <Carousel
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          photos={photos}
        />
        <CarouselDots total={photos.length} activeIndex={currentIndex} />
      </div>
    </div>
  );
}
