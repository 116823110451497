import LinkButton from "../../../LinkButton/LinkButton";
import { SmButtons } from "../../../SmButtons/SmButtons";
import "./HeaderButtons.scss";

export function HeaderButtons() {
  return (
    <div className="HeaderButtons">
      <SmButtons />
      <LinkButton
        variant="highlightOnHover"
        className="chatbot-link"
        href="https://t.me/hochunaiti_bot"
      >
        Чат-бот
      </LinkButton>
    </div>
  );
}
