import "./FillSearchApplication.scss";
import SearchButton from "./SearchButton/SearchButton";

export default function FillSearchApplication() {
  return (
    <div className="FillSearchApplication">
      <div className="content">
        <div className="hint">Оставить заявку на поиск</div>
        <SearchButton />
      </div>
    </div>
  );
}
