/* eslint-disable react/jsx-no-target-blank */
import FillSearchApplication from "../../FillSearchApplication/FillSearchApplication";
import "./Intro.scss";
import IntroPic from "./intro-pic.jpg";

export default function Intro() {
  return (
    <div className="Intro">
      <div className="introText">
        <h1>Как найти пропавшего военного в Украине?</h1>

        <p>
          Единый центр поиска военнослужащих армии РФ в Украине “Хочу найти” –
          гуманитарный проект{" "}
          <a href="https://koordshtab.gov.ua/" target="_blank">
            Координационного штаба по вопросам обращения с военнопленными
          </a>
          . Запущен в знак приверженности Украины принципам международного
          гуманитарного права.
        </p>
        <p>
          С помощью{" "}
          <a href="https://t.me/hochunaiti_bot" target="_blank">
            чат-бота{" "}
            <span style={{ textTransform: "uppercase" }}>«Хочу найти»</span>
          </a>{" "}
          возможно получить информацию о пребывании военнослужащих армии РФ в
          плену, а также о пропавших без вести и погибших, чьи тела и останки
          были найдены и идентифицированы в Украине.
        </p>

        <FillSearchApplication />
      </div>

      <img className="introPic" src={IntroPic} alt="" />
    </div>
  );
}
