import { HeaderButtons } from "./HeaderButtons/HeaderButtons";
import LogoAndMotto from "./LogoAndMotto/LogoAndMotto";
import "./Header.scss";

export function Header() {
  return (
    <header className="Header">
      <div className="headerContentContainer">
        <LogoAndMotto />
        <HeaderButtons />
      </div>
    </header>
  );
}
