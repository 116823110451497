import Logo600plus from "./600+.svg";
import Logo599_ from "./599-.svg";
import "./CondensedLogo.scss";

export default function CondensedLogo() {
  return (
    <a
      href="https://hochunaiti.com"
      className="CondensedLogo"
      aria-label="ХОЧУ НАЙТИ. Единый центр поиска военнослужащих армии РФ в Украине"
    >
      <picture>
        <source
          srcSet={Logo599_}
          media="(max-width: calc(600px - 1px + 17px))"
        />
        <img
          src={Logo600plus}
          alt="ХОЧУ НАЙТИ. Единый центр поиска военнослужащих армии РФ в Украине"
        />
      </picture>
    </a>
  );
}
