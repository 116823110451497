import { PropsWithChildren, useState } from "react";
import "./FaqItem.scss";

export interface FaqItemProps extends PropsWithChildren {
  title: string;
}
export default function FaqItem({ title, children }: FaqItemProps) {
  const [expanded, setExpanded] = useState(false);

  return (
    <li
      className={["FaqItem", expanded ? "expanded" : ""].join(" ")}
      onClick={() => {
        setExpanded(!expanded);
      }}
    >
      <div className="wrapper">
        <h4>{title}</h4>
        <div className="content">{children}</div>
      </div>
    </li>
  );
}
