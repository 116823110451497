import { Header } from "./Header/Header";
import "./HeaderAndIntro.scss";
import Intro from "./Intro/Intro";

export default function HeaderAndIntro() {
  return (
    <div className="HeaderAndIntro">
      <Header />
      <Intro />
    </div>
  );
}
